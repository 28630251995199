<template>
	<div class="avatar">{{ initials }}</div>
</template>

<script setup>
	import {computed} from "vue";
	import { useGlobalStore } from '@/stores/GlobalStore'

	const globalStore = useGlobalStore();
	const initials = computed(() =>  {
		const name = globalStore.user?.firstName +" "+globalStore.user?.lastName;
		return name.match(/(^\S\S?|\s\S)?/g).map(v=>v.trim()).join("").match(/(^\S|\S$)?/g).join("").toLocaleUpperCase();
	});
</script>

<style scoped>
	.avatar {
		@apply
		w-10 h-10
		rounded-3xl border-2 border-white
		text-white bg-gray-700
		text-center font-bold;
		line-height: 35px !important;
	}
</style>
